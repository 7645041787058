import React, { useEffect, useContext } from 'react'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import FbbtnImg from  '../../assets/images/facebook.svg'
import sessionDetails from "../../utils/sessionDetails";
import { useHistory } from "react-router-dom";
import * as config from '../../config'
import { AccountContext } from '../../App'
import moment from "moment";

export default function FbLogin ({preLoc,preData}) {
   
    const clientID = config.default.facebookLogin.Id; 
    const { setUserDetails } = useContext(AccountContext)
    const [details, setDetails] = sessionDetails();
    let history = useHistory();
    const responseFacebook = async (response) => {
        if (response && response.id) {
           // console.log(response);
            
            saveDetails(response)
            
        }

    }
    const saveDetails = (response) => {
        fetch( config.default.apiUrl + "user/save" , {
        method: "POST",
        body: JSON.stringify({
            login_id : response.id,
            email : response?.email,
            user_name : response.name,
            login_domain : "fb"
        }),
        headers: { 
            'Content-Type': 'application/json',
          }
      })
        .then(response => response.json())
        .then(data => {
          //console.log(data);
          setDetails({
            login_id : response.id,
            userId: data.user_id,
            userName: response.name,
            type: 'fb',
            address: data.public_address
        });
        setUserDetails({
            login_id : response.id,
            userId: data.user_id,
            userName: response.name,
            type: 'fb',
            address: data.public_address
        });
        localStorage.setItem('access_token',data.token_details?.access_token)
        localStorage.setItem('expiration_in',moment().add(data.token_details?.maxAge,'seconds'))
        if(preLoc && !preLoc.includes('login')){
            history.replace({ 
                pathname: preLoc,
                state: preData
           })
        }
        else{
            history.push({ 
                pathname: '/dashboard',
                state: preData
               })
            
        }
          
        }); 
      }

    return(
    <FacebookLogin
    appId= {clientID} 
    autoLoad={false}
    cookie={true}
    callback={responseFacebook}
    render={renderProps => (
        <button onClick={renderProps.onClick} className="btn loginBtnFb">
            <span><img src={FbbtnImg} alt="EcoBux" title="EcoBux" /></span>Log in with Facebook
        </button>
    )}
/>
    )
}