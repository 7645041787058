import React from 'react'
import { Link } from 'react-router-dom'
import flDownload from '../assets/images/file-download.svg'
import ethWhitePaper from '../assets/files/ethereum_white_paper.pdf'
import etheriumBuilt from '../assets/files/ecobux-WhitePaper.pdf'

const HowWeWork = () => {
    return(
        <>
        <section className="whatWeWork-section">
            <h1 className="pageTitle">How We Work</h1>
            <div className="container">
                <div className="innerContainer">
                    <div className="videoBorder">
                        <div className="videoWrap">
                            {/* <iframe width="560" height="315" src="https://www.youtube.com/embed/WIfu2469w48" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullscreen></iframe> */}
                            <iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/b-YXFNTmLYI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* <section className="AdditionalResources-section">
        <div className="container">
            <div className="innerContainer">
                <h2>Additional Resources</h2>
                <ul className="AdditionalResources">
                    <li><a href={etheriumBuilt} target="_blank">The EcoBux White Paper <img src={flDownload} alt="The EcoBux White Paper" title="Download File" /></a>
                    </li>
                    <li><a href={ethWhitePaper} target="_blank">Built on Ethereum <img src={flDownload} alt="Built on Ethereum" title="Download File" /></a></li>
                </ul>
            </div>
        </div>
    </section> */}


    <section className="faq-section">
        <div className="container">
            <div className="innerContainer">
                <h2 className="mb24">FAQ</h2>
                
                <div id="accordion">
                    <div className="faqAccordion">
                        <a role="button" className="accordionHeader" data-toggle="collapse" href="#question-1"
                            aria-expanded="true" aria-controls="question-1">
                            Do I actually own the land I am protecting
                        </a>
                        <div id="question-1" className="collapse show accordionBody" data-parent="#accordion">
                        No. The land belongs to Geoversity, the non-profit company dedicated to protecting the Mamoni Valley, however, you are the steward of the land.
                        </div>
                    </div>
                    <div className="faqAccordion">
                        <a role="button" data-toggle="collapse" href="#question-2" aria-expanded="false"
                            aria-controls="question-2">
                            Is my purchase tax deductible?
                        </a>
                        <div id="question-2" className="collapse accordionBody" data-parent="#accordion">
                        Yes it is. Your purchase is tax deductible as your money is going to Geoversity a non-profit charity enterprise. Geoversity will send an email containing the tax deductible statement.
                        </div>
                    </div>
                    <div className="faqAccordion">
                        <a role="button" data-toggle="collapse" href="#question-3" aria-expanded="false"
                            aria-controls="question-3">
                            Can I share my EcoBlock image to social media?
                        </a>
                        <div id="question-3" className="collapse accordionBody" data-parent="#accordion">
                        Yes, you can share your Ecoblocks on Facebook and Twitter through the share buttons. Additionally, you can share to instagram by saving the photo to your photo library and then posting.
                        </div>
                    </div>
                    <div className="faqAccordion">
                        <a role="button" data-toggle="collapse" href="#question-4" aria-expanded="false"
                            aria-controls="question-4">
                            Can my contributions be anonymous?
                        </a>
                        <div id="question-4" className="collapse accordionBody" data-parent="#accordion">
                        Yes, your contributions can remain anonymous if you do not want to share your name on our leaderboard. Simply check the “Make my contribution anonymous” box when checking out.
                        </div>
                    </div>
                </div>
                <div className="text-center mt40"><Link to="buy" className="btn btn-fill">Back This Project</Link></div>
            </div>
        </div>
    </section>

        </>
    )
}
export default HowWeWork