import React from 'react'
import { Link } from 'react-router-dom'
const TermsAndConditions = () => {
    return (
        <>
            <section className="inner-section pb80">
                <div className="container">
                    <h1 className="pageTitle">Terms of Service</h1>
                    <div className="innerContainer">

                        <div className="storyWrap">
                            <div className="storyContainer">
                                <p>Please read these terms of service carefully before using EcoBux website operated by
Geoversity Foundation
</p>
                            </div>
                            <h3 className="text-left">Conditions of Use</h3>
                            <div className="storyContainer">
                                <p>We will provide their services to you, which are subject to the conditions stated below in this
document. Every time you visit this website, use its services, or make a purchase, you accept
the following conditions. Therefore, we urge you to read them carefully.</p>
                            </div>

                            <h3 className="text-left">Privacy Policy</h3>
                            <div className="storyContainer">
                                <p>
                                Before you continue using our website, we advise you to read our privacy policy <Link to="privacypolicy"><u>https://www.ecobux.org/privacypolicy</u></Link> regarding our user data collection. It will help you better
understand our practices.</p>
                            </div>
                            <h3 className="text-left">Intellectual property</h3>
                            <div className="storyContainer">
                                <p>
                                You agree that all materials, products, and services provided on this website are the property of
Geoversity Foundation, its affiliates, directors, officers, employees, agents, suppliers, or
licensors including all copyrights, trade secrets, trademarks, patents, and other intellectual
property. You also agree that you will not reproduce or redistribute the EcoBux intellectual
property in any way, including electronic, digital, or new trademark registrations.
                                </p>
                                <p>
                                You grant EcoBux a royalty-free and non-exclusive license to display, use, copy, transmit, and
broadcast the content you upload and publish. For issues regarding intellectual property claims,
you should contact the company in order to come to an agreement.
                                    </p>
                            </div>
                            <h3 className="text-left">License to use the website</h3>
                            <div className="storyContainer">
                                <p>
                                You have a non-exclusive, limited, non-transferable, and revocable license to use the materials
available on this website. The materials may not be used for any other purpose, and the license
gets terminated the moment you stop using the website.
                                </p>
                            </div>
                            <h3 className="text-left">Communications</h3>
                            <div className="storyContainer">
                                <p>The entire communication with us is electronic. Every time you send us an email or visit our
website, you are going to be communicating with us. You hereby consent to receive
communications from us. If you subscribe to the news on our website, you are going to receive
regular emails from us. We will continue to communicate with you by posting news and notices
on our website and by sending you emails. You also agree that all notices, disclosures,
agreements, and other communications we provide to you electronically meet the legal
requirements that such communications be in writing.
                                    </p>
                            </div>
                            <h3 className="text-left">Applicable Law</h3>
                            <div className="storyContainer">
                                <p>By visiting this website, you agree that the laws of the State of California without regard to
principles of conflict laws, will govern these terms of service, or any dispute of any sort that
might come between EcoBux and you, or its business partners and associates.</p>
                            </div>
                            <h3 className="text-left">Disputes</h3>
                            <div className="storyContainer">
                                <p>Any dispute related in any way to your visit or transaction made through our website shall be
arbitrated by state or federal court of the Republic of Panama and you consent to exclusive
jurisdiction and venue of such courts.</p>
                            </div>
                            <h3 className="text-left">Comments, Reviews, and Emails</h3>
                            <div className="storyContainer">
                                <p>
                                Visitors may post content if it is not obscene, illegal, defamatory, threatening, infringing of
intellectual property rights, invasive of privacy or injurious in any other way to third parties.
Content must be free of software viruses, political campaign, and commercial solicitation.</p>
                                <p>We reserve all rights (but not the obligation) to remove and/or edit such content. When you post
your content, you grant EcoBux non-exclusive, royalty-free and irrevocable right to use,
reproduce, publish, modify such content throughout the world in any media.</p>
                            </div>
                            <h3 className="text-left">User Account</h3>
                            <div className="storyContainer">
                                <p>If you are an owner of an account on this website, you are solely responsible for maintaining the
confidentiality of your private user details (username and password). You are responsible for all
activities that occur under your account or password.</p>
                                <p>We reserve all rights to terminate accounts, edit or remove content and cancel orders in their
sole discretion.</p>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
        </>
    )

}

export default TermsAndConditions;