import React, { useState,useContext, useEffect } from 'react'
import FbLogin from './login/FbLogin'
import GLogin from './login/GLogin'
import BtnMinusLight from  '../assets/images/minus-light.svg'
import BtnPlusLight from  '../assets/images/plus-light.svg'
import sessionDetails from "../utils/sessionDetails";
import BtnPaypal from "../assets/images/paypal.svg"
import { AccountContext } from '../App'
import LoadScript from '../utils/LoadScript'
import Paypal from '../component/paypal/Paypal'
import { useHistory } from "react-router-dom";
import { getHeaderToken } from '../service/utility'
import * as config from '../config'
import { data, parseJSON } from 'jquery'
const CLIENT_ID = config.default.paypalClientID;
const Checkout = (props) => {
    const [loaded, error] = LoadScript(
        `https://www.paypal.com/sdk/js?client-id=${CLIENT_ID}`
    );
    const { userDetails,setUserDetails } = useContext(AccountContext)
    const [amount] = useState(props.location.state.amount);
    const [quantity, setQuantity] = useState(props.location.state.qty)
    const [details] = sessionDetails();
    const [islogin,setIslogin] = useState(details && details.userId);
    const [payActive,setPayActive] = useState(false)
    const [payDone,setPayDone] = useState(false)
    const [checkout, setCheckout] = useState(false)
    const [paySatus, setPaySatus] = useState(null)
    const [loading,setLoading] = useState(false)
    const [transactionTid, setTransactionTid ] = useState(null)
    const [errorMessage, setErrorMessage ] = useState(null)
    let history = useHistory();
    //console.log(userDetails)
    const getProductDetails = obj => {
        const price = obj.amount;
        if(price == 15 && typeof price == 'number'){
            return { product_type: 'EcoBlock',number_of_block: 1, total_ecoblock : (1 * quantity), price :15 }
        } else if(price == 45 && typeof price == 'number'){
            return { product_type: 'EcoZone',number_of_block: 3, total_ecoblock : (3 * quantity), price:45 }
        } else if(price == 450 && typeof price == 'number'){
            return { product_type: 'EcoSector',number_of_block: 30, total_ecoblock : (30 * quantity), price: 450 }
        } else if(price == 1500 && typeof price == 'number'){
            return { product_type: 'EcoSystem',number_of_block: 100, total_ecoblock : (100 * quantity),price: 1500 }
        } else {
            return { product_type: 'Pledge',number_of_block: 0, total_ecoblock : 0, price:price }
        }
    }
    const { product_type } = getProductDetails(props.location.state)
    const handleQuantity = val => {
        val = val.replace(/[^\d]/g, '')
        if(val === 0){
            val = 1;
        }
       // console.log(val)
        setQuantity(val);
    }
    // useEffect(() => {
    //     const saveTransaction = async _ => {
    //         const header = await getHeaderToken(userDetails.type,userDetails.login_id);
    //     if(paySatus.status == "COMPLETED"){
    //         const prDetails = getProductDetails(props.location.state)
    //         prDetails.user_id = details.userId;
    //         prDetails.quantity = quantity
    //         prDetails.total_amount = quantity * amount
    //         prDetails.transaction_id = paySatus.id
    //         prDetails.address = details.address
    //         setPayDone(true)
    //         fetch( config.default.apiUrl + "user/saveTransaction" , {
    //             method: "POST",
    //             body: JSON.stringify(prDetails),
    //             headers: header
    //           })
    //             .then(response => response.text())
    //             .then(data => { 
    //                 console.log(data);
    //                 if(data > 0){
    //                      // another fetch for ecoblock   
    //                     const apiName = product_type == "Pledge" ? "giveFuture" : "giveBlock"
    //                      fetch( config.default.apiUrl + "user/" + apiName , {
    //                         method: "POST",
    //                         body: JSON.stringify(prDetails),
    //                         headers: header
    //                       })
    //                         .then(response => response.text())
    //                         .then(rdata => { 
    //                             console.log(rdata);
    //                             if(rdata == "success"){
    //                                 history.push({ 
    //                                     pathname: '/dashboard'
    //                                 })
    //                             }
    //                             else{
    //                                 setLoading(false)
    //                             }
    //                         });
                        
    //                 }
    //                 else{
    //                     setLoading(false)
    //                 }
    //             });
    //         }
    //         else {
    //             alert('some error occured')
    //         }
    //         //setLoading(false)
    //     }
    //     if(paySatus) saveTransaction();
    // }, [paySatus])

    useEffect(() => {
        const saveTransaction = async _ => {
            const header = await getHeaderToken(userDetails.type,userDetails.login_id);
            if(paySatus.status == "COMPLETED"){

                const prDetails = getProductDetails(props.location.state)

                const postAssignBlockData = {
                    u_id: details.userId,
                    no_of_blocks: prDetails.number_of_block*quantity,
                    product_type: prDetails.product_type,
                    t_id: parseInt(transactionTid),
                    transaction_id: paySatus.id
                };

                const apiName = "assignedBlocks"
                //const apiName = product_type == "Pledge" ? "giveFuture" : "assignedBlocks"
                    fetch( config.default.apiUrl + "user/" + apiName , {
                        method: "POST",
                        body: JSON.stringify(postAssignBlockData),
                        headers: header
                    })
                    .then(response => response.json())
                    .then(rdata => { 
                        //console.log(rdata);
                        if(rdata && (rdata.fnassignblocks >= 0)){
                            history.push({ 
                                pathname: '/dashboard'
                            })
                        }
                        else{
                            setLoading(false);
                            setTransactionTid(null);
                            alert('some error occured')
                        }
                    });
            }
            else {
                alert('some error occured')
            }
            //setLoading(false)
        }
        if(paySatus) saveTransaction();
    }, [paySatus])

    const handlePayPalClick = async _ => {
        const prDetails = getProductDetails(props.location.state);
        const header = await getHeaderToken(userDetails.type,userDetails.login_id);
        prDetails.user_id = details.userId;
        prDetails.quantity = quantity
        prDetails.total_amount = quantity * amount
        prDetails.transaction_id = null;
        prDetails.address = details.address;
        
        if(!header){
            window.location.href = '/login'
        }

        fetch( config.default.apiUrl + "user/saveTransaction" , {
            method: "POST",
            body: JSON.stringify(prDetails),
            headers: header
          })
            .then(response => response.text())
            .then(data => {
                //console.log(data)
                //setErrorMessage
                // if(data > 0){
                //     setTransactionTid(data);
                //     setCheckout(true);
                // }
                // else{
                //     alert('Something Wrong...')
                // }
                //total_ecoblock
                if(data){
                    try {
                        let dataJson = JSON.parse(data);

                        if(dataJson.success && (dataJson.available_block >= prDetails.total_ecoblock)){
                            setTransactionTid(dataJson.t_id);
                            setCheckout(true);
                        }
                        else{
                            setErrorMessage(`Sorry ${dataJson.available_block} EcoBlocks are available`);

                            setTimeout(()=>{
                                setErrorMessage(null);
                            }, 10000)
                        }
                    } catch (e) {
                        alert('Something Wrong...')
                    }
                }
                else{
                    alert('Something Wrong...')
                }
            });

        
    }

    return (
            
         <>
            {(loaded && checkout &&
                <div className="modalWindow showModal">
                    <div className="modalContent">
                        <div className="modalHeader">
                            <h2 className="modalTitle">Payment Method</h2>
                            <span title="Close" onClick={() => { setLoading(false); setCheckout(false) }} className="modal-close"><i className="fa fa-times"></i></span>
                        </div>
                        <div className="modalBody">
                            <Paypal setCheckout={setCheckout} setLoading={setLoading} amount={quantity * amount} setPaySatus = {setPaySatus} />
                        </div>
                    </div>
                </div>
            )
            }
        <section className="inner-section text-center buyTop">
        <h1>Checkout</h1>
        </section>

    <section className="inner-section pt24 pb80">
        <div className="container">
            <div className="innerContainer">
                <div className="checkoutWrap">
                    <div className= { islogin ? "checkoutBlock completed" : "checkoutBlock active" }>
                        <div className="checkoutBlockTitle">
                            <h2>Login</h2>
                        </div>
                        
                        <div className="checkoutContent">
                            <div className="login text-center">
                                
                                <FbLogin preLoc="checkout" preData = { props.location.state } />
                            </div>
                            <div className="login text-center">
                                
                                <GLogin preLoc= "checkout" preData = { props.location.state }/>
                            </div>
                        </div>
                        
                    </div>
                        
                    <div className= { islogin ?  ( payActive ? "checkoutBlock review completed" : "checkoutBlock review active") : "checkoutBlock review"}>
                        <div className="checkoutBlockTitle">
                            <h2>Review Cart</h2>
                        </div>
                        <div className="checkoutContent">
                            { product_type != "Pledge" && (
                                <>
                            <div className="reviewCard">
                                <h4>{product_type}</h4>
                                <div className="right">
                                    <div className="cartForm">
                                        <span className="cursorPointer" onClick={() => setQuantity(q => q > 1 ? q - 1 : 0) } ><img src={BtnMinusLight} alt=""/></span>
                                        <input type="text" className="inputText"  onChange={(e) => { handleQuantity(e.target.value) }}  value={quantity} />
                                        <span className="cursorPointer" onClick={() => setQuantity(q => +q + 1) } ><img  src={BtnPlusLight} alt=""/></span>
                                    </div>
                                    <span className="amount">$ {amount}</span>                                
                                    <span className="cross cursorPointer"><img src="images/cross-light.svg" alt=""/></span>                                
                                </div>
                            </div>
                            </>
                            )}
                            <hr/>
                            <div className="reviewTotal">
                                <h4>Total</h4>
                                <h4>$ {(quantity * amount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</h4>
                            </div>
                            <div className="text-right mt32">
                                <button type="button" className="btn btn-fill" onClick={() => quantity > 0 ? setPayActive(true) : setPayActive(false) } id="confirmBtm">Confirm</button>
                            </div>
                        </div>
                    </div>
                    {
                        loading && (
                            <div className="pageLoader">
                            <div className="loading"></div>
                        </div> 
                        )
                    }
                    <div className= { islogin && payActive ? "checkoutBlock pay active" : "checkoutBlock pay" }>
                        <div className="checkoutBlockTitle">
                            <h2>Pay with PayPal</h2>
                        </div>
                        <div className="checkoutContent">
                            <div className="login text-center" onClick={handlePayPalClick} >
                                <button className="btn paypal">
                                    <img src= {BtnPaypal} alt="EcoBux" title="EcoBux"/>
                                </button>
                                {errorMessage != null &&
                                    <div style={{
                                        color: 'red',
                                        paddingTop: '7px'
                                    }}>
                                        {errorMessage}
                                    </div>
                                }
                                
                            </div>
                        </div>
                    </div>
                </div>
                

            </div>
        </div>
    </section>
        </>
    )

}

export default Checkout;