import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import * as config from '../config'


const CLIENT_ID = config.default.paypalClientID;
const Buy = () => {
    const [amount, setAmount] = useState('');
    const [quantity, setQuantity] = useState(1)


    const divClicked = (evt) => {
        evt.persist();
        evt.target.parentElement.querySelector("input[type=number]").focus();

    }

    const handleQuantity = val => {
        val = val.replace(/[^\d]/g, '')
        if (val === 0) {
            val = 1;
        }
        setQuantity(val);
    }
    const handleAmount = val => {
        if (!val || val.match(/^\d{1,}(\.\d{0,2})?$/)) {
            setAmount(val);
        }

    }
    return (
        <>

            <section className="inner-section text-center buyTop">
                <h1>Save The Mamoní Valley, Panama</h1>
                <span className="textStyle1 fontRaleway">Back this Project</span>
                <h4 className="pt32 mb0">Select your option below</h4>
            </section>

            <section className="inner-section pt24 pb80">
                <div className="container">
                    <div className="innerContainer">
                        <div className="radioCardItem"  >
                            <input className="radio-btn" name="buyCheckList" id="radio1" onChange={(evt) => divClicked(evt)} type="radio" />
                            <label className="radio-label" htmlFor="radio1">
                                <span className="title">Pledge without a Reward</span>
                                <p>Support the Mamoní valley with no reward.</p>
                                <div className="quantityForm">
                                    <div className="buyCardBottom">
                                        <span className="mr-2">Pledge value:</span>
                                        <div className="inputGroup mr-16">
                                            <span>$</span>
                                            <input type="number" value={amount} onChange={(e) => { handleAmount(e.target.value) }} className="inputField placeholderHideFocus" placeholder="" />
                                        </div>
                                        <Link className="btn btn-fill" to={{ pathname: amount > 0 ? "/checkout" : "", state: { qty: 1, amount: amount } }}> Checkout</Link>
                                    </div>
                                </div>
                            </label>
                        </div>
                        <div className="radioCardItem">
                            <input className="radio-btn" onChange={(evt) => { divClicked(evt); setQuantity(1); }} name="buyCheckList" id="radio2" type="radio" />
                            <label className="radio-label" htmlFor="radio2">
                                <span className="title">EcoBlock</span><span className="amount">15</span><span className="usd">USD</span>
                                <p>An EcoBlock is 100sqft of rainforest in the Mamoní Valley. Through the EcoBux website you can view your EcoBlock on our map and share it on social media.</p>
                                <div className="quantityForm">
                                    <div className="buyCardBottom">
                                        <span className="mr-2">Quantity:</span>
                                        <div className="inputGroup qtnTextBoxSize mr-16" >
                                            <input type="number" min="1" value={quantity} onChange={(e) => { handleQuantity(e.target.value) }} className="inputField text-center" defaultValue="1" />
                                        </div>
                                        <Link className="btn btn-fill" to={{ pathname: quantity > 0 ? "/checkout" : "", state: { qty: quantity, amount: 15 } }}> Checkout</Link>
                                    </div>
                                </div>
                            </label>
                        </div>
                        <div className="radioCardItem">
                            <input className="radio-btn" onChange={(evt) => { divClicked(evt); setQuantity(1); }} name="buyCheckList" id="radio3" type="radio" />
                            <label className="radio-label" htmlFor="radio3">
                                <span className="title">EcoZone</span><span className="amount">45</span><span className="usd">USD</span>
                                <p>
                                    An EcoZone is 3 EcoBlocks. An Ecoblock is 100sqft of rainforest in the Mamoní Valley.
                                    Through the EcoBux website you can view your EcoBlocks on our map and share them on social
                                    media.
                        </p>
                                <div className="quantityForm">
                                    <div className="buyCardBottom">
                                        <span className="mr-2">Quantity:</span>
                                        <div className="inputGroup qtnTextBoxSize mr-16" >
                                            <input type="number" value={quantity} onChange={(e) => { handleQuantity(e.target.value) }} className="inputField text-center" defaultValue="1" />
                                        </div>
                                        <Link className="btn btn-fill" to={{ pathname: quantity > 0 ? "/checkout" : "", state: { qty: quantity, amount: 45 } }}> Checkout</Link>
                                    </div>
                                </div>
                            </label>
                        </div>
                        <div className="radioCardItem">
                            <input className="radio-btn" onChange={(evt) => { divClicked(evt); setQuantity(1); }} name="buyCheckList" id="radio-4" type="radio" />
                            <label className="radio-label" htmlFor="radio-4">
                                <span className="title">EcoSector</span><span className="amount">450</span><span className="usd">USD</span>
                                <p>
                                    An EcoSector is 30 EcoBlocks. An Ecoblock is 100sqft of rainforest in the Mamoní Valley. Through the EcoBux website you can view your EcoBlocks on our map and share them on social media.
                        </p>
                                <div className="quantityForm">
                                    <div className="buyCardBottom">
                                        <span className="mr-2">Quantity:</span>
                                        <div className="inputGroup qtnTextBoxSize mr-16" >
                                            <input type="number" value={quantity} onChange={(e) => { handleQuantity(e.target.value) }} className="inputField text-center" defaultValue="1" />
                                        </div>
                                        <Link className="btn btn-fill" to={{ pathname: quantity > 0 ? "/checkout" : "", state: { qty: quantity, amount: 450 } }}> Checkout</Link>
                                    </div>
                                </div>
                            </label>
                        </div>
                        <div className="radioCardItem">
                            <input className="radio-btn" onChange={(evt) => { divClicked(evt); setQuantity(1); }} name="buyCheckList" id="radio5" type="radio" />
                            <label className="radio-label" htmlFor="radio5">
                                <span className="title">EcoSystem</span><span className="amount">1500</span><span className="usd">USD</span>
                                <p>
                                    An EcoSystem is 100 EcoBlocks. An Ecoblock is 100sqft of rainforest in the Mamoní Valley.
                                    Through the EcoBux website you can view your EcoBlocks on our map and share them on social
                                    media.
                        </p>
                                <div className="quantityForm">
                                    <div className="buyCardBottom">
                                        <span className="mr-2">Quantity:</span>
                                        <div className="inputGroup qtnTextBoxSize mr-16" >

                                            <input type="number" value={quantity} onChange={(e) => { handleQuantity(e.target.value) }} className="inputField text-center" defaultValue="1" />
                                        </div>
                                        {/* <button type="button" className="btn btn-fill"  >Checkout</button> */}
                                        <Link className="btn btn-fill" to={{ pathname: quantity > 0 ? "/checkout" : "", state: { qty: quantity, amount: 1500 } }}> Checkout</Link>
                                    </div>
                                </div>
                            </label>
                        </div>

                    </div>
                </div>
            </section>
        </>

    )

}
export default Buy