import React,{useEffect, useState, useContext} from 'react'
import { Link } from 'react-router-dom'
import Logo from '../assets/images/logo.svg'
import { useHistory } from "react-router-dom";
import sessionDetails from "../utils/sessionDetails";
import { AccountContext } from '../App'

const Header = ({pTitle}) => {
    let history = useHistory();
    const [activeMenu,setActiveMenu] = useState(false);
    const [sticky,setSticky] = useState(false);
    const [details, setDetails] = sessionDetails();
    const { setUserDetails } = useContext(AccountContext)
    const updateOnResize = _ => {
        setActiveMenu(false)
    }
    const handleScroll = event => {
       
        let eleScroll = event.srcElement.scrollingElement.scrollTop;
        
        if (eleScroll > 50) {
            setSticky(true)
            }
            else {
            setSticky(false)
            }
    }

    useEffect(() => {
        document.title = `EcoBux - ${ pTitle ? (pTitle) : 'Home'}`
        updateOnResize();
        window.addEventListener("resize", updateOnResize);
        window.addEventListener('scroll', handleScroll);
        return () => { 
            window.removeEventListener("resize", updateOnResize) 
            window.removeEventListener("resize", handleScroll) 
        };
      }, []);
    
    return (
       
        <>
            <header className={`headerFixed ${sticky ? 'sticky' : ''}`} >
            {/* <div className="topBar">We are launching on Nov 25!</div> */}
                <nav className="navbar navbar-expand-lg">
                    <div className="container">
                        <Link className="navbar-brand siteLogo" to="/">
                            <img src={Logo} alt="EcoBux" title="EcoBux" />
                        </Link>
                        <button className={`menuToggler ${activeMenu ? 'active' : ''}`} onClick={() => { setActiveMenu(!activeMenu) }} ></button>
                        <div className={`siteMenu ${activeMenu ? 'active' : ''}`}>
                            <ul className="navbar-nav ml-auto">                                
                                <li>
                                <Link className="menuLink" to="buy">Back This Project</Link>
                                </li>
                                <li>
                                    <Link className="menuLink" to="howwework">Learn More</Link>
                                </li>
                              
                                {(details && details.userId) ? (
                                    <>
                                    <li>
                                        <Link className="menuLink" to="/dashboard">Dashboard</Link>
                                    </li>
                                    <li>
                                        <Link className="btn btn-outline-custom" onClick={() => {
                                            if (details.type === "fb") {
                                                //  window.FB.logout()
                                            } else {
                                                //window.gapi.auth2.getAuthInstance().disconnect()
                                            }
                                            setDetails(null)
                                            localStorage.clear();
                                            setUserDetails(null)
                                            history.push("/");
                                        }} >  LOG OUT </Link>
                                    </li>
                                    </>
                                 ) : (
                                    <li>
                                        <Link className="btn btn-outline-custom" to="/login"> LOG IN </Link>
                                    </li>
                                 )
                                   
                                }


                                
                            </ul>
                        </div>
                    </div>
                </nav>
            </header>
        </>
    )
}
export default Header