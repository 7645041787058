  import React, { useState} from 'react';
import logo from './logo.svg';
import { BrowserRouter as Router, Switch, Redirect, Route } from "react-router-dom";
import './App.css';

import'bootstrap/dist/css/bootstrap.min.css';
import'bootstrap/dist/js/bootstrap.bundle.min';
import './assets/css/customStyle.css'
import './assets/css/font-awesome.min.css'
import pageArea from "./layout/ContentArea";
import Home from './component/Home'
import Login from './component/login/Login'
import Footer from './layout/Footer'
import AboutUs from './component/AboutUs'
import HowWeWork from './component/HowWeWork'
import Buy from './component/Buy'
import Checkout from './component/Checkout'
import PrivacyPolicy from './component/PrivacyPolicy'
import TermsAndConditions from './component/TermsAndConditions'
import Dashboard from './component/Dashboard'
import Sitemap from './component/Sitemap'
import AuthenticatedRoute from "./AuthenticatedRoute";
import ScrollToTop from './layout/scrollToTop'
import ServerError from './layout/ServerError'


const AccountContext = React.createContext({
  userDetails: {},
  setUserDetails: () => {
    
  }
});

function App() {
  const [userDetails, setUserDetails] = useState( () => {
    try {
      return JSON.parse(window.localStorage.getItem("user_session") || null);
    } catch (e) {
      return null;
    }
  });
  
  let routesNoLogin = [
    {
      path:'server-error',
      exact:true,
      component: pageArea(ServerError,"Server Error")
    },
    {
      path: "/home",
      exact: true,
      component: pageArea(Home,"Home")
    },
    {
      path: "/login",
      exact: true,
      component: pageArea(Login,"Login")
    },
    {
      path: "/aboutus",
      exact: true,
      component: pageArea(AboutUs,"About Us")
    },
    {
      path: "/howwework",
      exact: true,
      component: pageArea(HowWeWork,"How We Work")
    },
    {
      path: "/buy",
      exact: true,
      component: pageArea(Buy,"Buy")
    },
    {
      path: "/Checkout",
      exact: true,
      component: pageArea(Checkout,"Checkout")
    },
    {
      path: "/PrivacyPolicy",
      exact: true,
      component: pageArea(PrivacyPolicy,"Privacy Policy")
    },
    {
      path: "/termsandconditions",
      exact: true,
      component: pageArea(TermsAndConditions,"Terms And Conditions")
    },
    {
      path: "/Sitemap",
      exact: true,
      component: pageArea(Sitemap,"Sitemap")
    }

  ]
  let authRoutes = [
    {
      path: "/dashboard",
      exact: true,
      component: pageArea(Dashboard,"Dashboard")
    }
  ]

 
  return (
    <Router>
      <ScrollToTop />
      <AccountContext.Provider value={{ userDetails, setUserDetails }}>
        <Switch>
          <Route
            exact path="/"
            component={pageArea(Home,"Home")}
          />
        {routesNoLogin.map(route => (
                        <Route
                          key={route.path}
                          path={route.path}
                          exact={route.exact}
                          component={route.component}
                        />
                      )
                    )
        }
        {authRoutes.map(route => (
                        <AuthenticatedRoute
                          key={route.path}
                          path={route.path}
                          exact={route.exact}
                          component={route.component}
                        />
        ))}
        <Route component={pageArea(ServerError,"Server Error")} />
        </Switch>
      </AccountContext.Provider>
      <Footer/>
    </Router>
  )
}

export default App;
export {AccountContext };

