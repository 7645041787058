import React from "react";
import moment from "moment";
import { Redirect, Route } from "react-router-dom";

const AuthenticatedRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      localStorage.getItem("user_session") && localStorage.getItem("user_session")  != null && checkTokenStatus() == true ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/login",
            state: { from: props.location }
          }}
        />
      )
    }
  />
);

const checkTokenStatus = _ => {

  try {
    const expiration_in = localStorage.getItem('expiration_in')
    const df = moment(expiration_in).diff(moment(), 'seconds');
    
    //redirect to loing if expiration time left than 5 seconds
    if (df > 5){
      return true;
    }
    else {
      
      localStorage.clear();
      return false;
    }
  } catch (error) {
    console.log(error)
    return false;
  }
    
} 

export default AuthenticatedRoute;
