const dev = {
    googleLogin: {
      Id: "28302126494-i71f3lg1ds8pbsm8g5cihu31kilncrm6.apps.googleusercontent.com"
    },
    facebookLogin: {
        Id: "1049333142203664"
        
    },
    paypalClientID: "ATD-hS7nA4V7YskBwoyjUMNwPWDisXkBTzojszXeSakinE2E5CRfRT9fHFuTswosZTKQgqS-4wiEu43e", //"AeFZiU6d3tfNEp59YgEKWGsEzVM-yxYf0p7FMQH9RfUe7Manstfe9dERtDvpGDaK101r9AuR-NCQzb08",
    //apiUrl: 'https://p72ijkfs11.execute-api.us-east-1.amazonaws.com/dev/',
    appUrl: 'https://qa.ecobux.digitalavenues.net/',
    mapShareImage: 'https://map-images.ecobux.org/blocks/mapImageName.png',
    //apiUrl: 'https://tyb542phc0.execute-api.us-east-1.amazonaws.com/prod/'
    //apiUrl: 'https://api.qa.ecobux.digitalavenues.net/'
    apiUrl: 'https://qa-api.ecobux.org/'
  };
  
  const prod = {
    googleLogin: {
        Id: "809462279853-hvks0jedd9qckakdseena1ahmt94ijqs.apps.googleusercontent.com"
      },
      facebookLogin: {
          Id: "378267933388180"
      },
      paypalClientID: "ARdAhXUGOpEqd_bGAAcHFtazrLlkcpijiK9bzFo_Qvi6u2mX00V25x27RtwHj33BiP2vYA7fPB91BTX5", //"AeFZiU6d3tfNEp59YgEKWGsEzVM-yxYf0p7FMQH9RfUe7Manstfe9dERtDvpGDaK101r9AuR-NCQzb08"
      apiUrl: 'https://api.ecobux.org/',
      appUrl: 'https://ecobux.org/',
      mapShareImage: 'https://map-images.ecobux.org/blocks/mapImageName.png'
      //apiUrl: 'https://p72ijkfs11.execute-api.us-east-1.amazonaws.com/dev/'

  };
  
  const config = process.env.REACT_APP_STAGE === 'production'
    ? prod
    : dev;
  
  export default {
    ...config
  };