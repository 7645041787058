import React from 'react'
import { Link } from 'react-router-dom'
import LogoWhite from '../assets/images/logo-white.svg'
import FooterInstaIocn from '../assets/images/instagram-icon-footer.svg'
import FooterFacebookIocn from '../assets/images/facebook-icon-footer.svg'
import FooterTwitterIocn from '../assets/images/twitter-icon-footer.svg'
const Footer = () => {
    return (
        <>
            <footer className="footer">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4 col-lg-3">
                        <Link to="/" className="footerLogo">
                            <img src={LogoWhite} alt="EcoBux" title="EcoBux" /></Link>
                        </div>
                            <div className="col-md-8">
                                <div className="row footerCol">
                                    <div className="col-md-4">
                                        <ul className="footerMenu">
                                            <li><Link to="/">Home</Link></li>
                                            <li><Link to="aboutus">About Us</Link></li>
                                            <li><Link to="buy">Buy</Link></li>
                                            <li><Link to="howwework">How We Work</Link></li>
                                        </ul>
                                    </div>
                                    <div className="col-md-4">
                                        <ul className="footerMenu">
                                            <li><Link to="privacypolicy">Privacy Policy</Link></li>
                                            <li><Link to="termsandconditions">Terms of Service</Link></li>
                                            <li><Link to="sitemap">Sitemap</Link></li>
                                        </ul>
                                    </div>
                                    <div className="col-sm-4">
                                        <ul className="footerSocial">
                                            {/* <li>Social Share</li> */}
                                            <li>
                                            <a href="https://www.instagram.com/ecobux" target="_blank" className="customIocn"><img src={FooterInstaIocn} alt="EcoBux" title="EcoBux" /></a>
                                            <a href="https://twitter.com/ecobux" className="customIocn" target="_blank"><img src={FooterTwitterIocn} alt="EcoBux" title="EcoBux" /></a>
                                            <a href="https://www.facebook.com/Ecobux" className="customIocn facebookFooter" target="_blank"><img src={FooterFacebookIocn} alt="EcoBux" title="EcoBux" /></a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div className="row mt40 copyright">
                                    <div className="col-md-12">
                                        Copyright {new Date().getFullYear()} &copy; All Rights Reserved
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </footer>
        </>
    )
        }
export default Footer