
import moment from "moment";
import * as config from '../config'

async function getHeaderToken(type='',id=null){
    const access_token = localStorage.getItem('access_token')
    const expiration_in = localStorage.getItem('expiration_in')
    const df = moment(expiration_in).diff(moment(), 'seconds');
   // console.log('df' + expiration_in)
    //if token valid than 120 seconds go with current token


    if(df > 60) {
        return {
            'Authorization': access_token,
            'Content-Type': 'application/json',
        }
    }
    else {
        const response = await fetch( config.default.apiUrl + "user/refreshToken" , {
            method: "GET",
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': access_token
              }
          });
          if(response.status == 200){
            const data = await response.json();
            localStorage.setItem('access_token',data?.access_token)
            localStorage.setItem('expiration_in',moment().add(data?.maxAge,'seconds'));
            return {
                'Authorization': data?.access_token,
                'Content-Type': 'application/json',
            }
          }
          else{
            localStorage.removeItem("access_token");
            localStorage.removeItem("expiration_in");
            localStorage.removeItem("user_session");
            return false;
          }
        }
    }

export { getHeaderToken }