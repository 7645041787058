import React, { useContext } from 'react'
import FbLogin from '../login/FbLogin'
import GLogin from '../login/GLogin'
import { AccountContext } from '../../App'

import { useHistory } from "react-router-dom";
const Login = (props) => {
    const { setUserDetails } = useContext(AccountContext)
    let history = useHistory();
    if(localStorage.getItem("user_session") && localStorage.getItem("user_session")){
        history.push('/')
    }
    else{
        setUserDetails(null)
    }
    
    return (

        <section className="loginPage">
            <div className="loginInner">
                <div className="container">
                    <div className="row">
                        <div className="col-md-9 col-lg-9 col-xl-6  ml-auto mr-auto">
                            <h1>Log in to EcoBux</h1>
                            <div className="sideBorderBox loginBoxWrap">
                                <div className="loginBox">
                                    <FbLogin preLoc={props.location.state?.from.pathname} />
                                    <GLogin preLoc={props.location.state?.from.pathname}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}

export default Login