import React, { useContext } from "react";
import Header from "./Header";

// const classNames = require("classnames");

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    window.location.href = "/server-error";
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
   // console.log(error, errorInfo);
    // window.location.href = "/server-error";
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return  <h1>Something went wrong.</h1>;
    }

    return this.props.children;
  }
}

const pageArea = (Component,title) => ({ ...props }) => {
//console.log(title)
  return (
    <>
      <Header pTitle= {title} />
        <div>
          <ErrorBoundary>
            <Component {...props} />
          </ErrorBoundary>
        </div>
    </>
  );
};

export default pageArea;
