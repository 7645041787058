import React, { useEffect } from 'react'
import LoadScript from '../utils/LoadScript'
const SocialSharing = ({data_url,data_title,data_description,data_media}) => {
    const [loaded, error] = LoadScript(
        `//s7.addthis.com/js/300/addthis_widget.js#pubid=ra-5f4c8f3015bdde1d`
    );
    console.log(data_url)
    useEffect(() => {
        try {

            if (loaded && typeof window.addthis !== 'undefined') { window.addthis.layers.refresh(); }
        }
        catch (ex) {

        }
    }, [loaded])
    return(
        <div className="socials">
            {/* <a href="https://www.instagram.com/ecobux" target="_blank" className="customIocn"><img src={instaIocn} alt="Instagram" /></a> */}
            <div className="addthis_inline_share_toolbox" data-url={data_url} data-title={data_title}
                data-description="THE DESCRIPTION"
                data-media={data_media}></div>
        </div>
    )

}

export default SocialSharing