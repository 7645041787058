import { useState, useEffect } from "react";

export default () => {
 
  const [data, setData] = useState(() => {
    try {
      return JSON.parse(window.localStorage.getItem("user_session") || null);
    } catch (e) {
      return null;
    }
  });

  useEffect(() => {
    if(data)
      window.localStorage.setItem("user_session", JSON.stringify(data));
    else {
      window.localStorage.removeItem("user_session")
    }
  }, [data]);

  return [data, setData];
};
