import React from 'react'

const Paypal = ({setCheckout,amount,setPaySatus,setLoading}) => {
    
    const [paid, setPaid] = React.useState(false);
    const [error, setError] = React.useState(null);
    const paypalRef = React.useRef();
    React.useEffect(() => {
        window.paypal
          .Buttons({
            createOrder: (data, actions) => {
              setLoading(true)
              return actions.order.create({
                intent: "CAPTURE",
                purchase_units: [
                  {
                    description: "Your description",
                    amount: {
                      currency_code: "USD",
                      value: amount,
                    },
                  },
                ],
              });
            },
            onApprove: async (data, actions) => {
              
              const order = await actions.order.capture();
              console.log(order);
              setPaid(true);
              setCheckout(false)
              setPaySatus(order)
              
            },
            onError: (err) => {
            //   setError(err),
              console.error(err);
              setCheckout(false)
              setLoading(false)
            },
            onCancel:(res) => {
                console.log('res');
                setLoading(false)
                setCheckout(false)
            }
          })
          .render(paypalRef.current);
      }, []);
      if (paid) {
        return <div>Payment successful.!</div>;
      }
    
      // If any error occurs
      if (error) {
        return <div>Error Occurred in processing payment.! Please try again.</div>;
      }
    return (
        <>
            <div>
            <h4>Total Amount in USD : {amount} </h4>
            <div ref={paypalRef} />
            </div>
        </>
    )
}

export default Paypal;
