import React,{useContext} from 'react'
import GoogleLogin from 'react-google-login';
import GlBtnImg from '../../assets/images/google.svg'
import sessionDetails from "../../utils/sessionDetails";
import { useHistory } from "react-router-dom";
import * as config from '../../config'
import { AccountContext } from '../../App'
import moment from "moment";

export default function GLogin({preLoc,preData}) {
    console.log(preLoc)
    const googleId = config.default.googleLogin.Id; //"28302126494-i71f3lg1ds8pbsm8g5cihu31kilncrm6.apps.googleusercontent.com";
    const [details, setDetails] = sessionDetails();
    const { setUserDetails } = useContext(AccountContext)
    let history = useHistory();
    const responseGoogle = (response) => {
        if (response && response.googleId) {
           //console.log(response);
            saveDetails(response)
          }
    }

    
    const saveDetails =  (response) => {
        fetch( config.default.apiUrl + "user/save" , {
        method: "POST",
        body: JSON.stringify({
	
            login_id : response.profileObj.googleId,
            email : response.profileObj.email,
            user_name : response.profileObj.givenName,
            login_domain : "google"
        }),
        headers: { 
            'Content-Type': 'application/json',
          }
      })
        .then(response => response.json())
        .then(data => {
          //console.log(data);
          setDetails({
            login_id : response.profileObj.googleId,  
            userId: data.user_id,
            userName: response.profileObj.givenName,
            type: 'google',
            address: data.public_address
        });
        setUserDetails({
            login_id : response.profileObj.googleId,
            userId: data.user_id,
            userName: response.profileObj.givenName,
            type: 'google',
            address: data.public_address
        });
        localStorage.setItem('access_token',data.token_details?.access_token)
        localStorage.setItem('expiration_in',moment().add(data.token_details?.maxAge,'seconds'))
        if(preLoc && !preLoc.includes('login')){
            history.replace({ 
                pathname: preLoc,
                state: preData
           })
        }
        else{
            history.push({ 
                pathname: '/dashboard',
                state: preData
               })
            
        }
          
        }); 
      }
    return(
            <GoogleLogin
                    clientId={googleId}
                    autoLoad={false}
                    onSuccess={responseGoogle}
                    onFailure={responseGoogle}
                    cookiePolicy={'single_host_origin'}
                    render={renderProps => (
                        <button onClick={renderProps.onClick} className="btn loginBtnGplus">
                            <span><img src={GlBtnImg} alt="EcoBux" title="EcoBux" /></span>Log in with Google
                        </button>
                    )}
            />
    )
}