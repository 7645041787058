import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import HomeBanner from '../assets/images/heroImage.jpg'
import progressImg from '../assets/images/progress-white.png'
import LoadScript from '../utils/LoadScript'
import LeaftLetMap from './map/LeaftLetMap'
import * as config from '../config'
import SocialSharing from './SocialSharing'

const Home = () => {
    const [leaderBoardData, setLeaderBoardData] = useState(null);
    const [fundRaised, setFundRaised] = useState(0)
    const [unOwnedBlocks, setUnOwnedBlcok] = useState(null)
    const [loaded] = LoadScript(
        `//s7.addthis.com/js/300/addthis_widget.js#pubid=ra-5f4c8f3015bdde1d`
    );
    useEffect(() => {
        try {

            if (loaded && typeof window.addthis !== 'undefined') { window.addthis.layers.refresh(); }
        }
        catch (ex) {

        }
    }, [loaded])
    useEffect(() => {

        try {
            const getLeaderBoard = async _ => {
                fetch(config.default.apiUrl + "user/getLeaderBoardData/10", {
                    method: "GET",
                    headers: {
                        'Content-Type': 'application/json',
                    }
                })
                    .then(response => response.json())
                    .then(data => {
                        setLeaderBoardData(data)
                    }).catch(err => {
                        setLeaderBoardData([])
                    });
            }
            getLeaderBoard();
        } catch (error) {
            setLeaderBoardData([])
        }


    }, [])

    useEffect(() => {

        try {
            const getFundRaised = async _ => {
                fetch(config.default.apiUrl + "user/getTotalFundRaised", {
                    method: "GET",
                    headers: {
                        'Content-Type': 'application/json',
                    }
                })
                    .then(response => response.json())
                    .then(data => {
                        setFundRaised(data)
                    }).catch(err => {
                        setFundRaised(0)
                    });
            }
            getFundRaised();
        } catch (error) {
            setFundRaised(0)
        }


    }, [])



    useEffect(() => {
        try {
            const getUnOwnedBlcok = async _ => {
                //unOwnedBlocks
                fetch(config.default.apiUrl + "user/getUnOwnedBlocks", {
                    method: "GET",
                    headers: {
                        'Content-Type': 'application/json',
                    }
                })
                    .then(response => response.json())
                    .then(data => {
                        // console.log(data);
                        setUnOwnedBlcok(data)
                    }).catch(err => {
                        setUnOwnedBlcok([])
                    });
            }
            getUnOwnedBlcok();
        } catch (error) {
            setUnOwnedBlcok([])
        }

    }, [])


    return (
        <>
            <section className="fullscreenBanner fullscreen-image" style={{ backgroundImage: `url(${HomeBanner})` }}>
                <div className="bannerContect">
                    <h1>Save the Mamoní Valley, Panama</h1>
                    <h3 className="fontQuicksand">Protect 5 hectares of secondary rainforest today</h3>
                    <Link to="buy" className="btn btn-fill">Back This Project</Link>
                </div>
            </section>
            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="videoBorder">
                                <div className="videoWrap">
                                    {/* <iframe width="560" height="315" src="https://www.youtube.com/embed/WIfu2469w48" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullscreen></iframe> */}
                                    <iframe width="560" height="315" src="https://www.youtube.com/embed/b-YXFNTmLYI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                </div>
                            </div>
                            {/* <div className="videoBg"></div> */}
                        </div>
                        <div className="col-md-6">
                            <div className="homeVideoRightBlk">
                                <h3>Together We Can Change the World</h3>
                                <p>Share your plot with friends and family on Facebook and Twitter.</p>
                                <SocialSharing data_url={`https://www.youtube.com/embed/b-YXFNTmLYI`} data_title={`EcoBux.org`}
                                    data_description={`Watch and share this video to see how you can protect biodiversity at EcoBux.org`}
                                    data_media={`https://www.youtube.com/embed/b-YXFNTmLYI`} />
                                {/* <div className="socials">
                                    <a href="https://www.instagram.com/ecobux" target="_blank" className="customIocn"><img src={HomeInstaIocn} alt="Instagram" /></a>
                                    <div className="addthis_inline_share_toolbox" data-url={`http://example.com/blog/post/2009/05/01 `} data-title={`the title `}
                                        data-description={`the description`}
                                        data-media={`http://example.com/blog/post/2009/05/01 `}>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>

            </section>
            <section className="progressWrap">
                <div className="container">
                    <div className="progressPreloaderWrap">
                        <h2 className="mb60">Progress</h2>
                        <div className="progressPreloader">
                            <div className="progressImg">
                                <div className="progressOverlap" style={{ height: ((fundRaised / 49500) * 100).toFixed(2).toString() + '%' }}></div>
                                <img src={progressImg} alt="" />
                            </div>
                            <div className="progressStepWrap">
                                <div className="progressStep top">
                                    <span className="bar" />
                                    <span>$49,500</span>
                                </div>
                                <div className="progressStep mid active" style={{ bottom: ((fundRaised / 49500) * 100).toFixed(2).toString() + '%' }}>
                                    <span className="bar" />
                                    <span>${fundRaised.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}<br /><span>raised</span></span>
                                </div>
                                <div className="progressStep bottom">
                                    <span className="bar" />
                                    <span>$0</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="sec-projectPiloto">
                <div className="container">
                    <div className="projectPilotoMap ml-auto mr-auto">
                        {/* <h2 class="projectPilotoTitle mb40">Project Piloto</h2> */}
                        <div className="projectPilotoMapWrap">
                            <div className="projectPilotoMapimg">
                                {/* <img src={HomeMap} alt="" /> */}
                                {/* <LeaftLetMap mapHeightWidth={{ height: '100%', width: '100%' }} /> */}
                                <LeaftLetMap unOwnedBlocks={unOwnedBlocks} mapHeightWidth={{ height: '100%', width: '100%' }} />
                            </div>
                            <div className="leaderboardSection">
                                <h3>Leaderboard</h3>
                                <div className="leaderboardContent">
                                    <div className="leaderboardContent">
                                        <ol>

                                            {

                                                (leaderBoardData && leaderBoardData.length > 0) && leaderBoardData.map((ele, index) => (
                                                    <>
                                                        {parseInt(ele.total_ecoblock)>0 &&
                                                            <li><p>{ele.user_name}</p> <span>{ele.total_ecoblock}</span></li>
                                                        }
                                                    </>
                                                ))}
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="text-center mt40">
                        <Link to="howwework" className="btn btn-fill">Learn More</Link>
                    </div>
                </div>
            </section>
        </>

    )

}

export default Home