import React, { useEffect, useRef, useState } from 'react'
import data from './miami.json'
import bbox from '@turf/bbox'
import 'leaflet/dist/leaflet.css';
import { Map, TileLayer,  GeoJSON } from 'react-leaflet';


const LeafletMap = ({unOwnedBlocks,ownedBlocks, selectedIndex}) =>  {

  const [markerPosition, setMarkerPosition] =  useState({ lat:42.6944, lng:23.3328  })
  const [allUsMapData] = useState(data);
  const mapRef = useRef(null)
  console.log(mapRef)
  //console.log(mapRef)
  const [bounds,setBounds] = useState(null);
  useEffect(()=> {
    const bboxArray = bbox(allUsMapData);
    const corner1 = [bboxArray[1], bboxArray[0]];
     const corner2 = [bboxArray[3], bboxArray[2]];
    
    setBounds([corner1, corner2])

  },[])
  
  console.log(ownedBlocks)
  console.log(unOwnedBlocks)
  useEffect(() => {
    const { current = {} } = mapRef;

  },[mapRef])

  useEffect(() => {
   try {
    mapRef && mapRef.current && mapRef.current.leafletElement.eachLayer((layer) => {
      //layer.options.zIndex = 1;
      if(ownedBlocks && ownedBlocks.indexOf(layer.feature?.properties?.toString()) > -1) {
        layer.options.zIndex = 999;
        layer.bringToFront();
      } else if  (unOwnedBlocks && unOwnedBlocks.indexOf(layer.feature?.properties?.toString()) > -1){
        //layer.options.zIndex = 1;
      } else {
        //layer.options.zIndex = 99;
        //layer.bringToFront();
        layer.bringToBack();
      }
      
     })
   } catch (error) {
     
   }

  },[unOwnedBlocks,ownedBlocks])

  const styleMe = (fe) => {
    
      const ownedStyle = {
        fillColor: 'white',
        weight: 2,
        opacity: 1,
        color: 'white',  //Outline color
        fillOpacity: 0.6
      }
    
      const otherOwnedStyle = {
        fill : 1,
        color: "black",
        weight: 1
      }
      const UnOwnedStyle = {
        fill : 1,
        //fillColor: "#A8A8A8",
        color: "#A8A8A8",
        //color: 'red',
        weight: 1
      }
      //return UnOwnedStyle;
      if(ownedBlocks && ownedBlocks.indexOf(fe.properties.POLY_ID.toString()) > -1) {
        return ownedStyle;
      } else if  (unOwnedBlocks && unOwnedBlocks.indexOf(fe.properties.POLY_ID.toString()) > -1){
        return UnOwnedStyle;
      } else {
        return otherOwnedStyle;
      }

  }
    return (
       
      <Map bounds={bounds} zoom={24} ref={mapRef}  scrollWheelZoom={false}>
      
       <TileLayer maxZoom={24}  url="https://leaf-let.ecobux.org/Mapnik/{z}/{x}/{y}.png"   />
         <GeoJSON  data={allUsMapData} style={styleMe}  />
    </Map>
      
    )

  } 




export default LeafletMap