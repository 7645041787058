import React from 'react'
import { Link } from 'react-router-dom'
import LucasPrImg from '../assets/images/lucas-EcoBux-Profile.png'
import ZachPrImg from '../assets/images/zachary-EcoBux-Profile.png'
import Geoversity from '../assets/images/geoversity.png'
const AboutUs = () => {


    return (
        <>
            <section className="">
                <div className="innerWrapper">
                    <div className="container">
                        <h1 className="pageTitle">About Us</h1>
                        <div className="sideBorderBox aboutImgGallery">
                            <div className="row">
                                <div className="col-md-6 item">
                                    <div className="imgWrap">
                                        <img src={ZachPrImg} alt="EcoBux" title="EcoBux" />
                                    </div>
                                    <h3>Zachary Wiel</h3>
                                </div>
                                <div className="col-md-6 item">
                                    <div className="imgWrap">
                                        <img src={LucasPrImg} alt="EcoBux" title="EcoBux" />
                                    </div>
                                    <h3>Lucas Oberwager</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8">
                            <div className="storyContainer">
                                <h1 className="pageTitleLeft text-left">Our Story</h1>
                                <p>We are Bay Area youths dedicated to the crossroads of environmentalism and technology. We are tech nerds and tree huggers focused on the implementation of a crowdfunding model for the conservation world. We partnered with Geoversity, a non-profit Panamanian organization centered in the Mamoní Valley.</p>
                                <p>Geoversity is dedicated to rainforest conservation and has already protected 5000 hectares of rainforest. With your help we will protect the rest of the Mamoní Valley. We plan on partnering with other non-profit organizations throughout the world to expand the EcoBux ecosystem. Together we will save the world.</p>
                                <p>Interested in partnering with us? Contact us at <a href="mailto:info@ecobux.org">info@ecobux.org</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className="container">
                    <div className="partners sideBorderBox sideBorderTR">
                        <div className="partnersLeft">
                            <h3>Proud partners with Geoversity</h3>
                            <div className="partnersImg">
                                <img src={Geoversity} alt="geoversity" title="geoversity" />
                            </div>
                        </div>
                        <div className="partnersRight">
                            <Link className="btn btn-fill" to="buy">Back This Project</Link>
                        </div>

                    </div>
                </div>
            </section>
        </>

    )

}

export default AboutUs