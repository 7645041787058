import React from "react";
import { Redirect } from "react-router-dom";


export default function ServerError() {
  const error = {
    code: "500",
    message: "Internal Server Error"
  };
  return (
    <>
    <section className="inner-section pb80">
        <div className="container" style={{padding : '100px 0'}} >
        <div className="col-sm-8 text-center m-auto">
          <h3 className="pageError fontQuicksand">Sorry, something went wrong. Don’t worry, we’ll fix it soon.<br/>Please try again later.</h3>
        </div> 
      </div>
    </section>
    </>
  );
}