import React, { useEffect, useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import MapImg from '../assets/images/map.png'
import MapThumbImg from '../assets/images/map-thumb.png'
import LoadScript from '../utils/LoadScript'
import LeaftLetMap from './map/LeaftLetMap'
import * as config from '../config'
import { AccountContext } from '../App'
import data from './map/miami.json'
import SocialSharing from './SocialSharing'
import { getHeaderToken } from '../service/utility'

const Dashboard = () => {
    const { userDetails, setUserDetails } = useContext(AccountContext)
    const [userOwnedBlocks, setUserOwnedBlcok] = useState(null)
    const [unOwnedBlocks, setUnOwnedBlcok] = useState(null)
    const [showPopUp, setShowPopUp] = useState(false)
    const [selectedData, setSelectedData] = useState(null)
    const [selectedIndex, setSelectedIndex] = useState(-1)
    const shareMapPath = config.default.appUrl + 'static/media/map_image.png'
    const [loaded, error] = LoadScript(
        `//s7.addthis.com/js/300/addthis_widget.js#pubid=ra-5f4c8f3015bdde1d`
    );

    // useEffect(() => {
    //    const meta = document.createElement('meta');
    //     meta.setAttribute('og:description','description');
    //     //meta.content = "IE=edge";
    //     document.getElementsByTagName('head')[0].appendChild(meta);
    // },[])

    useEffect(() => {
        try {

            if (loaded && typeof window.addthis !== 'undefined') { window.addthis.layers.refresh(); }
        }
        catch (ex) {

        }
    }, [loaded])

    useEffect(() => {
        if (selectedIndex > -1)
            setSelectedData(data.features[userOwnedBlocks[selectedIndex]])
    }, [selectedIndex])

    useEffect(() => {
        
        const getUserOwnedBlocks = async _ => {
            const header = await getHeaderToken(userDetails.type,userDetails.login_id);
            //console.log(userDetails)
            //alert(userDetails.userId)
            //config.default.apiUrl + "user/userOwnedBlocks/" + userDetails.address
            fetch(config.default.apiUrl + "user/getUserOwnedBlocks/" + userDetails.userId, {
                method: "GET",
                headers: header
            })
                .then(response => response.json())
                .then(data => {
                    //console.log('own');
                    //console.log(data)
                    setUserOwnedBlcok(data)
                }).catch(err => {
                    setUserOwnedBlcok([])
                });
        }
        getUserOwnedBlocks();
    }, [])
    useEffect(() => {

        const getUnOwnedBlcok = async _ => {
            //unOwnedBlocks
            fetch(config.default.apiUrl + "user/getUnOwnedBlocks", {
                method: "GET",
                headers: await getHeaderToken(userDetails.type,userDetails.login_id)
            })
                .then(response => response.json())
                .then(data => {
                    setUnOwnedBlcok(data)
                }).catch(err => {
                    setUnOwnedBlcok([])
                });
        }
        getUnOwnedBlcok();
    }, [])


    return (
        <>
            <section className="inner-section">
                <h1 className="pageTitle">Dashboard</h1>
                <div className="container">
                    <div className="innerContainer">
                        <LeaftLetMap unOwnedBlocks={unOwnedBlocks} ownedBlocks={userOwnedBlocks} mapHeightWidth={{ height: '80%', width: '80%' }} />
                    </div>

                </div>
            </section>
            <section className="pb50">
                <div className="container">
                    <div className="innerContainer">
                        <h2 className="mb32">My EcoBlocks</h2>
                        <div className="row">
                        
                            {
                                //[1, 2, 3, 4, 5, 6].map((ele, index) => (
                                userOwnedBlocks && userOwnedBlocks.length > 0 ? (userOwnedBlocks.map((ele, index) => (

                                    <div key={index} className="col-sm-6 col-md-4 mb30" >
                                        <div className="card dashboardCard">
                                            <div className="card-body" onClick={() => { setSelectedIndex(index) }}>
                                                <span className="mapOverlay"></span>
                                                {/* <img src={MapThumbImg} alt="" /> */}
                                                <img src={config.default.mapShareImage.replace('mapImageName',ele)} alt="" />
                                                {/* <MapPopUp selectedData={data.features[ele]}   /> */}
                                            </div>
                                            <div className="card-footer">
                                                <div className="socials">
                                                    <SocialSharing 
                                                        data_url={config.default.mapShareImage.replace('mapImageName',ele)} 
                                                        data_title={"Ecobux"}
                                                        data_description="Check out my EcoBlock, I saved 100 sqft of Panamanian rainforest at EcoBux.org"
                                                        data_media={config.default.mapShareImage.replace('mapImageName',ele)} />
                                                    {/* <a href="https://www.instagram.com/ecobux" target="_blank" className="customIocn"><img src={instaIocn} alt="Instagram" /></a> */}
                                                    {/* <div className="addthis_inline_share_toolbox" data-url={`http://example.com/blog/post/2009/05/01 ${index}`} data-title={`the title ${index}`}
                                                        data-description={`the description ${index}`}
                                                        data-media={`http://example.com/blog/post/2009/05/01 ${index}`}></div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))) : (
                                        <div className="col-sm-12">
                                            <div className="cardBlank">
                                                <span>You don't own any EcoBlocks </span>
                                                <Link to="/buy"  className="btn btn-fill">Back This Project</Link>
                                                {/* <MapPopUp selectedData={data.features[ele]}   /> */}
                                            </div>
                                        </div>
                                    )
                            }
                        </div>
                    </div>
                </div>
                {selectedIndex > -1 && (
                    <>
                        <div className="modalWindow showModal mapModal">
                            <div className="modalContent">
                                <span className="close" onClick={() => {
                                    setSelectedIndex(-1)
                                }} ></span>
                                <div className="modalBody">
                                    <div className="modalLeft">
                                        <img src={config.default.mapShareImage.replace('mapImageName',selectedData?.properties.POLY_ID)} alt="" />
                                        {/* <MapPopUp selectedData={selectedData} /> */}
                                    </div>
                                    <div className="mapRight">
                                        <div className="mapRightTopContent">
                                            <h6>Block ID</h6>
                                            <p>#{selectedData?.properties.POLY_ID}</p>
                                            {/* <h6>Purchased on</h6>
                                            <p>May 18, 2020</p> */}
                                        </div>
                                        <div className="mapRightBtmContent">
                                            <ul className="socials">
                                                {/* <li><a href="#"><img src={HomeInstaIocn} alt="" /></a></li> */}
                                                {/* <li><a href="#"><img src={HomeTwitterIcon} alt="" /></a></li>
                                                <li><a href="#"><img src={HomefbIocn} alt="" /></a></li> */}
                                                 <SocialSharing 
                                                        data_url={config.default.mapShareImage.replace('mapImageName',selectedData?.properties.POLY_ID)} 
                                                        data_title={"Ecobux"}
                                                        data_description="Check out my EcoBlock, I saved 100 sqft of Panamanian rainforest at EcoBux.org"
                                                        data_media={config.default.mapShareImage.replace('mapImageName',selectedData?.properties.POLY_ID)} />
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <span className="arrow-left" onClick={() => { if (selectedIndex > 0) setSelectedIndex(selectedIndex - 1) }} ></span>
                                <span className="arrow-right" onClick={() => { if (selectedIndex < userOwnedBlocks.length - 1) setSelectedIndex(selectedIndex + 1) }}></span>
                            </div>
                        </div>
                    </>
                )}

            </section>

        </>
    )

}

export default Dashboard;