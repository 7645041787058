import React,{ useContext} from 'react'
import { Link } from 'react-router-dom'
import ethWhitePaper from '../assets/files/ethereum_white_paper.pdf'
import etheriumBuilt from '../assets/files/ecobux-WhitePaper.pdf'
import { AccountContext } from '../App'
import sessionDetails from "../utils/sessionDetails";
import { useHistory } from "react-router-dom";
const Sitemap = () => {
  let history = useHistory();
  const [details, setDetails] = sessionDetails();
  const { setUserDetails } = useContext(AccountContext)
    return (
        <>
        <section className="inner-section pb80">
        <div className="container">
          <h1 className="pageTitle">Sitemap</h1>
          <div className="innerContainer">
            <ul className="sitemap">
              <li><Link to="/" className="normalLink">Home</Link></li>                    
              <li><Link className="normalLink" to="buy">Buy</Link></li>                   
              <li><Link className="normalLink" to="howwework">How We Work</Link></li>
              <li>
              {(details && details.userId) ? (<Link className="normalLink" href="javascript:void(0)" onClick={() => {
                                        
                                        setDetails(null)
                                        localStorage.clear();
                                        setUserDetails(null)
                                        history.push("/");
                                    }} >
                                        Log Out
                                    </Link>) : (<Link className="normalLink" to="/login">
                                        Login
                                    </Link>)}
                                    </li>
              {/* <li><Link className="normalLink" to="login">Login</Link></li> */}
              <li><Link className="normalLink" to="aboutus">About Us</Link></li>
              <li><Link className="normalLink" to="privacypolicy">Privacy Policy</Link></li>
              {/* <li><a className="normalLink" href={etheriumBuilt} target="_blank">The EcoBux White Paper</a></li> */}
              <li><Link className="normalLink" to="termsandconditions">Terms of Service</Link></li>                    
              {/* <li><a className="normalLink" href={ethWhitePaper} target="_blank">Built on Ethereum</a></li> */}
              <li><Link className="normalLink" to="dashboard">Dashboard</Link></li>
            </ul>
          </div>
        </div>
      </section>    
        </>
    )

}

export default Sitemap;